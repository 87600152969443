import React, { useState } from 'react';
import PasswordScreen from './PasswordScreen';
import BirthdayScreen from './BirthdayScreen';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <>
      <div className="h-screen w-screen flex items-center justify-center bg-gray-100">
        {isAuthenticated ? (
          <BirthdayScreen />
        ) : (
          <PasswordScreen onSuccess={() => setIsAuthenticated(true)} />
        )}
      </div>
    </>
    
  );
}

export default App;
