import React, { useState } from 'react';

function PasswordScreen({ onSuccess }) {
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'zaheerlovesolivia') {
      onSuccess();
    } else {
      alert('Incorrect Password!');
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center space-y-4 bg-white p-6 rounded-lg shadow-lg"
    >
      <h1 className="text-2xl font-bold">Enter Password</h1>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="border p-2 rounded w-64 text-center focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <button
        type="submit"
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Submit
      </button>
    </form>
  );
}

export default PasswordScreen;
