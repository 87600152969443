import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Confetti from 'react-confetti';
import TypingEffect from './TypingEffect';


import Image1 from './Image1.png';
import Image2 from './Image2.png';



function BirthdayScreen() {
  const photos = [Image1, Image2]; // Replace with actual image paths

  const [isConfetti, setIsConfetti] = useState(true);

  useEffect(() => {
    setIsConfetti(true);

    const timer = setTimeout(() => {
        setIsConfetti(false);
      }, 5000); // Confetti lasts for 5 seconds
  
      return () => clearTimeout(timer); // Clean up the timer
    }, []);

  return (
    <div className="text-center space-y-6">
      <motion.h1
        className="text-8xl font-extrabold text-pink-500"
        initial={{
          scale: 0.2,
          rotate: 180, // Start rotated by 180 degrees
          opacity: 0, // Start with hidden text
          skewX: 150, // Apply a slight skew to make it feel "curled"
          x:150,
          y:300,
        }}
        animate={{
          scale: 1,
          rotate: 0, // End in a normal position
          opacity: 1, // Make the text fully visible
          skewX: 0, // Reset skew
          x:0,
          y:0,
        }}
        transition={{
          duration: 1, // Longer duration to let the curl happen smoothly
          ease: "easeInOut",
        }}
      >
        🎉 Happy Birthday! 🎉
      </motion.h1>
      {isConfetti && <Confetti 
        gravity={0.1}
      />}
      <motion.div
        className="flex justify-center space-x-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {photos.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Photo ${index + 1}`}
            className="w-32 h-32 rounded-lg shadow-lg border"
          />
        ))}
      </motion.div>
      <div className='flex items-center justify-center bg-gray-100'>
       <TypingEffect message="Today is December 12, 2024 which marks the calendar as the very special day for the most special woman in the entire world. As you go throughout your day, I want you to be reminded of all the people around you who want you to succeed and who truly care about you and those who will defend you to the end. Throughout the 6 years of us knowing together and half a decade since we've first started having feelings for each other, I always knew that you were the one for me. The one who completes me and the one who is my soulmate. Throughout the entire day, I wish you can feel the power of all your surrounding love strengthen your will to give you that last push for the rest of the semester. Through my periods of stress and hardships, my one thought that brings me out of depths of despair is the thought of you. There's nothing that would be better than us two meeting in person. Thank you for being that person I can rely on when I need and that person who makes me know what love is. I hope you can stand proud today knowing that you made it this far, happy birthday my love, Olivia <3! I love you so much <33 " speed={25} />
      </div>
    </div>
  );
}

export default BirthdayScreen;
