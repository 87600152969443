import React, { useState, useEffect } from 'react';

const TypingEffect = ({ message = '', speed = 100 }) => {
  const [displayedMessage, setDisplayedMessage] = useState('');

  useEffect(() => {
    let index = 0;

    // Ensure message is not empty and is defined
    if (message) {
      const typingInterval = setInterval(() => {
        if (index === 1){
            setDisplayedMessage((prevMessage) => prevMessage + message[1] + message[2]);
            index++;
        }
        else{
            setDisplayedMessage((prevMessage) => prevMessage + message[index]);
            index++;
        }

        if (index === message.length - 1) {
          clearInterval(typingInterval); // Stop typing once the full message is displayed
        }
      }, speed);

      return () => clearInterval(typingInterval); // Clean up the interval on unmount
    }
  }, [message, speed]);

  return (
    <div className="w-full max-w-3xl p-8 border-2 border-gray-300 rounded-lg shadow-lg bg-white">
      <textarea
        className="w-full h-[400px] p-4 text-lg border-2 border-gray-400 rounded-lg resize-none focus:outline-none"
        value={displayedMessage}
        readOnly
        placeholder="The message will be typed out here..."
      />
    </div>
  );
};

export default TypingEffect;
